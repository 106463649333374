import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/4cd8a442/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "home"
    }}>{`Home`}</h1>
    <p>{`This is my home on the web where I collect notes of a couple of my interests `}<a parentName="p" {...{
        "href": "./maths"
      }}>{`maths`}</a>{` and `}<a parentName="p" {...{
        "href": "./data"
      }}>{`data`}</a>{`.`}</p>
    <p>{`I intend to fill it in a haphazard manner.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      